<template>
  <div :is="layout">
    <v-container>
      <v-card  style="background:var(--v-bg-base)">
        <v-row class="mt-10" justify="center" align="center">
          <v-col cols="8">
            <v-card-title class="text-center text-h5 justify-center"
              >{{$t("sector_planning.totalTitle")}}</v-card-title
            >
            <v-divider></v-divider>
            <v-form ref="form">
              <v-card-title class="py-3 pl-0">{{$t("sector_planning.firstTitle")}}</v-card-title>
              <v-row class="justify-space-between">
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :label="$t('sector_planning.algorithmSelection')"
                    :items="algorithm_list"
                    item-value="id"
                    item-text="name"
                    v-model="info.algorithmSelection"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :label="$t('sector_planning.airspaceSelection')"
                    :items="airspace_list"
                    item-value="id"
                    item-text="name"
                    v-model="info.airspaceSelection"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('sector_planning.parameterInput')"
                    v-model="info.parameterInput"
                    placeholder="(1000,1000,10)(1000,1000,10)"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('sector_planning.flightPathNumber')"
                    type="number"
                    placeholder="001"
                    v-model="info.flightPathNumber"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-title class="py-3 pl-0">{{$t("sector_planning.secondTitle")}}</v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        suffix="nm"
                        placeholder="5"
                        type="number"
                         min="0"
                        v-model="info.grigSizeLength"
                       :label="$t('sector_planning.grigSizeLength')"
                        required
                      ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        suffix="nm"
                        placeholder="5"
                        type="number"
                         min="0"
                        v-model="info.grigSizeWidth"
                        :label="$t('sector_planning.grigSizeWidth')"
                        required
                      ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        suffix="FL"
                        placeholder="5"
                        type="number"
                         min="0"
                        v-model="info.grigSizeHeight"
                        :label="$t('sector_planning.grigSizeHeight')"
                        required
                      ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    type="number"
                     min="0"
                    placeholder="10"
                    :label="$t('sector_planning.sectorNumber')"
                    v-model="info.sectorNumber"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="info.heightLayer"
                    :label="$t('sector_planning.heightLayer')"
                    placeholder="245 345 355 365 595"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    type="number"
                     min="0"
                    placeholder="100"
                    v-model="info.VoronoiNumber"
                    :label="$t('sector_planning.VoronoiNumber')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-title class="py-3 pl-0"
                >{{$t('sector_planning.thirdTitle')}}</v-card-title
              >
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    placeholder="3"
                    :suffix="$t('minute')"
                     min="0"
                    type="number"
                    v-model="info.flightFlowTime"
                    :label="$t('sector_planning.flightFlowTime')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    placeholder="120"
                    :suffix="$t('seconds')"
                    type="number"
                    min="0"
                    v-model="info.resolveConflictTime"
                    :label="$t('sector_planning.resolveConflictTime')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    placeholder="120"
                    :suffix="$t('seconds')"
                    type="number"
                    min="0"
                    v-model="info.controlTransferTime"
                    :label="$t('sector_planning.controlTransferTime')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-title class="py-3 pl-0">{{$t("sector_planning.fourthTitle")}}</v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :suffix="$t('seconds')"
                    placeholder="1000"
                    type="number"
                    min="0"
                    v-model="info.maxLoad"
                    :label="$t('sector_planning.maxLoad')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :suffix="$t('seconds')"
                    type="number"
                    min="0"
                    placeholder="120"
                    v-model="info.minStayTime"
                    :label="$t('sector_planning.minStayTime')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    suffix="nm"
                    type="number"
                    placeholder="5"
                    min="0"
                    v-model="info.shortestDistance"
                    :label="$t('sector_planning.shortestDistance')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    suffix="%"
                    type="number"
                    placeholder="2"
                    min="0"
                    v-model="info.allowReenter"
                    :label="$t('sector_planning.allowReenter')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    suffix="%"
                    type="number"
                    min="0"
                    placeholder="2"
                    v-model="info.allowShortCross"
                    :label="$t('sector_planning.allowShortCross')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    suffix="%"
                    placeholder="20"
                    type="number"
                    v-model="info.AllowSectorImbalance"
                    min="0"
                    :label="$t('sector_planning.AllowSectorImbalance')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-title class="py-3 pl-0">{{$t("sector_planning.fifthTitle")}}</v-card-title>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    placeholder="500"
                    :label="$t('sector_planning.evolutionaryAlgebra')"
                    type="number"
                    min="0"
                    v-model="info.evolutionaryAlgebra"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t('sector_planning.populationSize')"
                    type="number"
                    placeholder="500"
                    min="0"
                    v-model="info.populationSize"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="info.crossProbability"
                    :label="$t('sector_planning.crossProbability')"
                    type="number"
                    min="0"
                     step="0.01"
                    placeholder="0.9"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="info.variationProbability"
                    :label="$t('sector_planning.variationProbability')"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.1"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="info.initCombinationCrossProbability"
                    step="0.01"
                    min="0"
                    :label="$t('sector_planning.initCombinationCrossProbability')"
                    type="number"
                    placeholder="0.3"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    step="0.01"
                    min="0"
                    v-model="info.SBXdistributionindex"
                    :label="$t('sector_planning.SBXdistributionindex')"
                    type="number"
                    placeholder="0.1"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="submit" color="accent">{{$t('confirm')}}</v-btn>
                <v-btn color="secondary2" class="mr-4" @click="reset"> {{$t('reset')}} </v-btn>
              </v-card-actions>

            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { get_list } from "../../../api/control_region";
export default {
  data() {
    return {
      layout: null,
      airspace_list: [],
      algorithm_list: [],
      info: {
        algorithmSelection: "",
        airspaceSelection: "",
        parameterInput:  "",
        flightPathNumber: null,
        grigSizeLength: null,
        grigSizeWidth: null,
        grigSizeHeight: null,
        sectorNumber: null,
        heightLayer: null,
        VoronoiNumber: null,
        flightFlowTime: null,
        resolveConflictTime: null,
        controlTransferTime: null,
        maxLoad: null,
        minStayTime: null,
        shortestDistance: null,
        allowReenter: null,
        allowShortCross:null,
        AllowSectorImbalance: null,
        evolutionaryAlgebra: null,
        populationSize: null,
        crossProbability: null,
        variationProbability: null,
        initCombinationCrossProbability: null,
        SBXdistributionindex: null,
      },
    };
  },
  methods: {
    submit() {
      if (this.info.heightLayer) {
        this.info.heightLayer = this.info.heightLayer.split(" ").map(Number);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    getAirSpaceList() {
      get_list().then((res) => {
        this.airspace_list = res.results;
      });
    },
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.algorithm_list = [
      { id: 1, name: "遗传算法" },
      { id: 2, name: "区域生长算法" },
    ];
    this.getAirSpaceList();
  },
};
</script>
<style scoped >
.row .col-12 .v-text-field{
   margin-left: 1.25rem;
   margin-right: 1.25rem;
}
@media screen and (min-width:960px) {
  .row .col-12:nth-child(3n)>.v-text-field{
    margin-right:0 !important;
}
.row .col-12:nth-child(3n+1)>.v-text-field{
    margin-left:0 !important;
}
}
@media screen and (min-width:600px) and (max-width:960px) {
  .row .col-12:nth-child(2n)>.v-text-field{
     margin-right:0 !important;
 }
 .row .col-12:nth-child(2n+1)>.v-text-field{
    margin-left:0 !important;
 }
}
@media screen and (max-width:600px) {
 .row .col-12 .v-text-field{margin-right:0 !important; margin-left:0 !important;}
}



</style>
